<!-- 选择产品 -->
<template>
  <!-- 续费产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage/index.vue";
import Dialog from "@/components/Dialog";
import { TEMPLATE_TYPE_DATA, TEMPLATE_TYPE_TEXT } from "@/utils/constant.js";
import { getTenantProductToRenew } from "@/api/tenant/base/info"; //所有商户
import {
  batchUpdateTemplateStatus,
  templateList,
  deleteTemplate,
  restoreTemplate,
} from "@/api/O2OMall/config/template";

export default {
  name: "TenantList",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 550,
        show: false,
        type: "O2OMallTemplate",
        formData: null,
      },
      options: {
        tableTitle: true,
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        getListApi: templateList,
        title: "商城模板",
        rowKey: "templateId",
        buttons: [
          {
            click: "add",
            label: "新增",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            btnType: "dropdown",
            trigger: "click", // 要求
            alertText: "确认要删除选中打印模板吗？",
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: restoreTemplate,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "reset",
            label: "刷新",
            right: true,
          },
        ],
        operates: {
          width: 80,
          list: [
            {
              text: "编辑",
              method: (row) => {
                this.$router.push({
                  path: "/O2OMall/config/template/decoratePage/index",
                  query: {
                    templateId: row.templateId,
                  },
                });
              },
            },
          ],
        },
        tabsColumns: TEMPLATE_TYPE_DATA.map((x) => ({
          title: x.label,
          type: x.value,
          defaultBody: x.value === 0 ? {} : { templateType: x.value },
          columns: [
            { prop: "templateName", label: "模板名称", minWidth: 150 },
            {
              prop: "templateType",
              label: "模板类型",
              minWidth: 100,
              formatter: (v) => TEMPLATE_TYPE_TEXT[v],
            },
            {
              type: "switch",
              prop: "isOnLine",
              label: "是否启用",
              minWidth: 140,
              active: true,
              inactive: false,
              activeLabel: "启用",
              inactiveLabel: "禁用",
              disabled: (v) => v,
              onClick: (t, row) => {
                this.$confirm("确认启用该模板吗？", "提示", {
                  confirmButtonText: "确认",
                  cancelButtonText: "取消",
                })
                  .then(() => {
                    this.handleEvent("updateOnline", row);
                  })
                  .catch(() => {
                    row.isOnLine = false;
                  });
              },
            },
            {
              prop: "isDefault",
              label: "系统默认",
              minWidth: 155,
              formatter: (v) => (v ? "是" : "否"),
            },
            { prop: "describe", label: "模板描述", minWidth: 180 },
            { prop: "updateBy", label: "修改人", minWidth: 150 },
            { prop: "updateTime", label: "修改时间", minWidth: 150 },
            { prop: "createBy", label: "创建人", minWidth: 150 },
            { prop: "createTime", label: "创建时间", minWidth: 150 },
          ],
        })),
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增商城模板",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del":
          try {
            await deleteTemplate(this.options.check.map((x) => x.templateId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "updateOnline":
          try {
            await batchUpdateTemplateStatus({
              templateIds: [row.templateId],
              isOnLine: row.isOnLine,
            });
            this.$message.success("设置成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
