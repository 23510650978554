var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("h2", [_vm._v("模板选择")]),
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              model: {
                value: _vm.data.style.templateNo,
                callback: function ($$v) {
                  _vm.$set(_vm.data.style, "templateNo", $$v)
                },
                expression: "data.style.templateNo",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("模板一"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "x-bc" }, [
          _c("h2", [_vm._v("展示设置")]),
          _c("i", {
            staticClass: "pointer",
            class: _vm.zhanshiIcon,
            staticStyle: { color: "#000000", "font-size": "20px" },
            on: { click: _vm.openZhanShi },
          }),
        ]),
        _vm.isOpenZhanShi
          ? _c("div", [
              _vm.data.style.templateNo <= 2
                ? _c("div", { staticClass: "marT10" }, [
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v("客户信息"),
                    ]),
                    _c("div", { staticClass: "x-bc marT10" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "x-f btnListBox" },
                        _vm._l(_vm.switchList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item x-c",
                              class: { borderColor: _vm.avatarItem == index },
                              staticStyle: { color: "#979797" },
                              style: { fontSize: item.fontSize + "px" },
                              on: {
                                click: function ($event) {
                                  return _vm.switchBtn("isAvatar", index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "x-bc marT10" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "x-f btnListBox" },
                        [
                          _c("el-switch", {
                            staticStyle: { zoom: ".8" },
                            attrs: { width: 40 },
                            model: {
                              value: _vm.data.style.isVipQrCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "isVipQrCode", $$v)
                              },
                              expression: "data.style.isVipQrCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "marT10" }, [
                _c("span", { staticClass: "fontS14" }, [_vm._v("资产信息")]),
                _vm.data.style.templateNo == 1
                  ? _c("div", [
                      _c("div", { staticClass: "x-bc marT10" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "170px" },
                                attrs: { size: "mini", placeholder: "请选择" },
                                on: { change: _vm.descriptionChange },
                                model: {
                                  value: _vm.data.style.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.style, "description", $$v)
                                  },
                                  expression: "data.style.description",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "x-bc marT10" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                size: "mini",
                                maxlength: 12,
                                placeholder: "最多12个字以内，可不填",
                              },
                              model: {
                                value: _vm.data.style.propertyMsg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "propertyMsg", $$v)
                                },
                                expression: "data.style.propertyMsg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.style.templateNo == 2
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "descriptionArr" },
                        _vm._l(_vm.options, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item x-bc" },
                            [
                              _c(
                                "div",
                                { staticClass: "x-f" },
                                [
                                  _c("iconpark-icon", {
                                    attrs: {
                                      name: "align-text-both",
                                      size: "20px",
                                    },
                                  }),
                                  _c("span", { staticClass: "marL10" }, [
                                    _vm._v(_vm._s(item.label)),
                                  ]),
                                ],
                                1
                              ),
                              _c("el-switch", {
                                staticStyle: { zoom: ".8" },
                                attrs: { width: 40 },
                                on: {
                                  change: function ($event) {
                                    return _vm.descriptionArr(item)
                                  },
                                },
                                model: {
                                  value: item.open,
                                  callback: function ($$v) {
                                    _vm.$set(item, "open", $$v)
                                  },
                                  expression: "item.open",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("el-divider"),
      _c("div", [
        _c("div", { staticClass: "x-bc" }, [
          _c("h2", [_vm._v("样式设置")]),
          _c("i", {
            staticClass: "pointer",
            class: _vm.yangShiIcon,
            staticStyle: { color: "#000000", "font-size": "20px" },
            on: { click: _vm.openYangShi },
          }),
        ]),
        _vm.isOpenYangShi
          ? _c("div", [
              _c("div", { staticClass: "x-bc" }, [
                _c("div", { staticClass: "x-f" }, [
                  _c("span", { staticClass: "marR20 lable color000" }, [
                    _vm._v("文字颜色"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.data.style.textColor))]),
                ]),
                _c(
                  "div",
                  { staticClass: "x-f btnListBox" },
                  [
                    _c("el-color-picker", {
                      on: { change: _vm.selectTextcolor },
                      model: {
                        value: _vm.data.style.textColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "textColor", $$v)
                        },
                        expression: "data.style.textColor",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "x-bc marT10" }, [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "x-f btnListBox" },
                  _vm._l(_vm.bgStyleList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item x-c",
                        class: { borderColor: _vm.bgStyleItem == index },
                        on: {
                          click: function ($event) {
                            return _vm.setBgStyle(item.style, index)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.name))])]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "marT10" }, [
                this.data.style.bgStyle == "image"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "imgBox x-c cursorP",
                          staticStyle: { overflow: "hidden" },
                          on: {
                            click: function ($event) {
                              return _vm.addImgClick()
                            },
                          },
                        },
                        [
                          _vm.data.style.bgImage == ""
                            ? _c("div", { staticClass: "y-c" }, [
                                _c("i", {
                                  staticClass: "el-icon-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    "margin-bottom": "5px",
                                  },
                                }),
                                _c("span", [_vm._v("添加图片")]),
                              ])
                            : _vm.data.style.bgImage != ""
                            ? _c("el-image", {
                                attrs: {
                                  src: _vm.data.style.bgImage,
                                  width: "100%",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontS12 marT10",
                          staticStyle: { color: "#bababa" },
                        },
                        [_vm._v("建议尺寸： 1370x320 像素的图片")]
                      ),
                    ])
                  : this.data.style.bgStyle == "color"
                  ? _c("div", [
                      _c("div", { staticClass: "x-bc" }, [
                        _c("div", { staticClass: "x-f" }, [
                          _c("span", { staticClass: "marR20 lable color000" }, [
                            _vm._v("背景颜色"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.data.style.cardBgColor)),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          [
                            _c("el-color-picker", {
                              model: {
                                value: _vm.data.style.cardBgColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "cardBgColor", $$v)
                                },
                                expression: "data.style.cardBgColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "x-bc marT10" }, [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "x-f btnListBox" },
                  _vm._l(_vm.chamferList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item x-c",
                        class: { borderColor: _vm.chamferItem == index },
                        on: {
                          click: function ($event) {
                            return _vm.setChamfer(item.style, index)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.name))])]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "x-bc marT10" }, [
                _vm._m(6),
                _c(
                  "div",
                  { staticClass: "x-f btnListBox" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "170px" },
                        attrs: { size: "mini", placeholder: "请选择" },
                        on: { change: _vm.cardStyleChange },
                        model: {
                          value: _vm.data.style.cardStyle,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "cardStyle", $$v)
                          },
                          expression: "data.style.cardStyle",
                        },
                      },
                      _vm._l(_vm.options2, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "x-f marB20" },
                  [
                    _c("span", { staticClass: "fontS14 marR50" }, [
                      _vm._v("上边距"),
                    ]),
                    _c("el-slider", {
                      staticClass: "slider",
                      attrs: { min: 0, max: 50 },
                      model: {
                        value: _vm.data.marT,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "marT", $$v)
                        },
                        expression: "data.marT",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marB20" },
                  [
                    _c("span", { staticClass: "fontS14 marR50" }, [
                      _vm._v("下边距"),
                    ]),
                    _c("el-slider", {
                      staticClass: "slider",
                      attrs: { min: 0, max: 50 },
                      model: {
                        value: _vm.data.marB,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "marB", $$v)
                        },
                        expression: "data.marB",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marB20" },
                  [
                    _c("span", { staticClass: "fontS14 marR50" }, [
                      _vm._v("左边距"),
                    ]),
                    _c("el-slider", {
                      staticClass: "slider",
                      attrs: { min: 0, max: 25 },
                      model: {
                        value: _vm.data.marL,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "marL", $$v)
                        },
                        expression: "data.marL",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marB20" },
                  [
                    _c("span", { staticClass: "fontS14 marR50" }, [
                      _vm._v("右边距"),
                    ]),
                    _c("el-slider", {
                      staticClass: "slider",
                      attrs: { min: 0, max: 25 },
                      model: {
                        value: _vm.data.marR,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "marR", $$v)
                        },
                        expression: "data.marR",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marB20" },
                  [
                    _c("span", { staticClass: "fontS14 radiuDistance" }, [
                      _vm._v("圆角"),
                    ]),
                    _c("el-slider", {
                      staticClass: "slider",
                      attrs: { min: 0, max: 50 },
                      model: {
                        value: _vm.data.borRadius,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "borRadius", $$v)
                        },
                        expression: "data.borRadius",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材库",
            visible: _vm.openIcon,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openIcon = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("微信头像"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [_vm._v("会员码")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("资产信息"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("资产描述"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("卡片背景"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("卡片倒角"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 lable color000" }, [
        _vm._v("卡片样式"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }