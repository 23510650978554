<template>
    <div>
        <el-tabs v-model="data.style.type" stretch>
            <el-tab-pane label="加微信群/个人微信号" name="WeChat">
                <div>
                    <div class="x-f">
                        <div>添加活码</div>
                        <el-tooltip class="item" effect="dark" :content="content" placement="top-start">
                            <i class="el-icon-question marL10"></i>
                        </el-tooltip>
                    </div>
                    <div class="x-f-end marT10">
                        <el-button type="primary" plain>添加活码</el-button>
                    </div>
                </div>
                <el-form :model="data">
                    <el-divider></el-divider>
                    <div class="">
                        <span class="fontS12" style="color: #969799">添加文案</span>
                        <el-form-item label="按钮名称">
                            <el-input v-model="data.style.btnName" :placeholder="data.style.btnName"></el-input>
                        </el-form-item>
                        <el-form-item label="标题">
                            <el-input v-model="data.style.title" placeholder="个人微信号、群名称或活动标题"></el-input>
                        </el-form-item>
                        <el-form-item label="描述">
                            <el-input v-model="data.style.describe" placeholder="请添加描述"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <el-divider></el-divider>
                <div>
                    <div class="x-bc">
                        <span>入口图片</span>
                        <el-radio-group v-model="data.style.isDefault" @change="raidoChange">
                            <el-radio :label="1">默认图片</el-radio>
                            <el-radio :label="2">自定义图片</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="data.style.isDefault == 2" class="marT10">
                        <div class="addImgBox x-c" @click="addImg">
                            <i
                                class="el-icon-plus"
                                v-if="data.style.imgUrl"
                                style="font-size: 26px; color: #bababa"
                            ></i>
                            <el-image
                                v-else-if="data.style.coverImg != ''"
                                :src="data.style.imgUrl"
                                width="100%"
                            ></el-image>
                        </div>
                        <span style="color: #969799" class="fontS12">入口图建议1:1比例</span>
                    </div>
                </div>
                    <div class="x-bc marT10">
                        <div class="x-f">
                            <span class="marR20 label">背景颜色</span>
                            <span>{{data.style.bgColor}}</span>
                        </div>
                        <div class="x-f btnListBox">
                            <el-color-picker v-model="data.style.bgColor" @change="selectBGcolor"></el-color-picker>
                        </div>
                    </div>
            </el-tab-pane>
            <el-tab-pane label="关注公众号" name="publicNum">
                <div class="marT20">
                    <span class="fontS12" style="color: #969799">选择访问途径</span>
                    <div class="marT20">
                        <div class="x-bc">
                            <span class="fontS12">小程序关注</span>
                            <el-checkbox v-model="data.style.applet"></el-checkbox>
                        </div>
                        <div class="fontS12 marT10" style="color: #969799">
                            仅在用户扫码进入微页面时可展示，用户可一键关注公众号，需要在微信后台开启 去配置
                            <a href="https://mp.weixin.qq.com/" target="_blank" style="color: #1c75f4">去配置</a>
                        </div>
                    </div>
                    <div class="marT20">
                        <div class="x-bc">
                            <span class="fontS12">小程序转公众号文章关注</span>
                            <el-checkbox v-model="data.style.appletGopublicNum"></el-checkbox>
                        </div>
                        <div class="fontS12 marT10" style="color: #969799">
                            支持所有访问方式，用户点击后跳转公众号文章获取二维码，长按识别关注公众号
                        </div>
                    </div>
                    <div class="marT20">
                        <div class="x-bc">
                            <span class="fontS12">H5</span>
                            <el-checkbox v-model="data.style.h5"></el-checkbox>
                        </div>
                        <div class="fontS12 marT10" style="color: #969799">
                            支持所有访问方式，长按识别二维码关注
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <span class="fontS12 marT10" style="color: #969799">若在小程序一个页面内同时选择了两款组件，用户扫码访问该页面时，仅展示微信官方组件。</span>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>

export default {
    name: 'zhangfen',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            content: '活码是用户推广个人微信号或粉丝群的二维码，适用于线上线下，吸引用户添加好友或粉丝群进活码是用户推广个人微信号或粉丝群的二维码，适用于线上线下，吸引用户添加好友或粉丝群进'
        }
    },
    methods: {
        templateMsg() {
            this.$emit('setTemp', this.data)
        },
        addImg() {
            this.$parent.$parent.materialOpen = true
        },
        // 是否默认图片
        raidoChange(val) {
            console.log('默认图片：', val)
            if(val == 1) {
                this.data.style.imgUrl = 'https://img01.yzcdn.cn/public_files/2019/05/09/50ab4e10f415beac6acf155d10f1f2cb.png!middle.webp'
            }
            this.templateMsg()
        },
        // 选择背景颜色
        selectBGcolor(value){
            this.data.style.bgColor = value
            this.templateMsg()
        },
    }
}
</script>

<style scoped>
.addImgBox {
    width: 80px;
    height: 80px;
    border: 1px solid #bababa;
    cursor: pointer;
}

</style>
