<template>
  <div class="container bg-Color">
    <div class="cardBox padd15">
      <!-- <el-select v-model="value" placeholder="微信小程序">
        <el-option
          v-for="item in 4"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <div class="x-f marT10">
        <el-button type="primary" size="mini" @click="newlyAdd">新增</el-button>
        <!--                <c-button class="whiteColor" test="新增" :bgColor="'#2db6f4'"></c-button>-->
        <!--                <c-button class="whiteColor" test="使生效" :bgColor="'#19bd6b'" @click=""></c-button>-->
        <!--                <c-button class="whiteColor" test="使失效" :bgColor="'#fd9801'" @click=""></c-button>-->
        <!-- <div style="color: red; font-size: 13px" class="marL10">
          注：注意当前门店未单独设置门店时，默认使用默认微主页
        </div> -->
      </div>
      <!-- <div class="marT10 x-f" style="color: #a2a2a2; font-size: 12px">
        不参与活动门店是否显示活动
        <el-switch
          class="marL10"
          v-model="switchVal"
          active-color="#b4b3b3"
          inactive-color="#b4b3b3"
        ></el-switch>
        <span class="marL10">{{ !switchVal ? '隐藏' : '显示' }}</span>
      </div> -->
    </div>
    <div class="bg-Color marT20">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column prop="date" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.templateContent)"
              >编辑</el-button
            >
            <!--                        <el-button type="text" @click="">复制</el-button>-->
            <!-- <el-button type="text" @click="">修改标题</el-button> -->
            <el-button
              type="text"
              style="color: #ff3e54"
              @click="deleteTemp(scope.row.templateId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop=""
          align="center"
          label="标题"
          width="180"
        ></el-table-column> -->
        <el-table-column
          prop="describe"
          align="center"
          label="页面"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="address" align="center" label="门店" width="100">
          <template> -->
        <!--                        <el-dropdown>-->
        <!-- <span class="el-dropdown-link">
              全部门店
            </span> -->
        <!--                            <el-dropdown-menu slot="dropdown">-->
        <!--                                <el-dropdown-item>宝安店</el-dropdown-item>-->
        <!--                                <el-dropdown-item>南山店</el-dropdown-item>-->
        <!--                            </el-dropdown-menu>-->
        <!--                        </el-dropdown>-->
        <!-- </template>
        </el-table-column> -->
        <el-table-column prop="address" align="center" label="模板操作" width="180">
          <template v-slot="scope">
            <el-switch
              @change="updateTemplateStatus(scope.row, $event)"
              v-model="scope.row.isOnLine"
              :active-color="'#409dfe'"
              :inactive-color="'#b4b3b3'"
              active-text="启用"
              inactive-text="未启用"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isDefault" align="center" label="是否默认模版" width="120">
          <template v-slot="scope">{{ scope.row.isDefault ? "是" : "否" }}</template>
        </el-table-column>
        <el-table-column
          prop="describe"
          align="center"
          label="模板说明"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import {
  batchUpdateTemplateStatus,
  templateList,
  deleteTemplate,
} from "@/api/O2OMall/config/template";
export default {
  name: "index",
  components: {
    CButton,
  },
  data() {
    return {
      value: "",
      switchVal: false,
      tableData: [],
      value1: false,
    };
  },
  created() {
    this.getTemplateList();
  },
  methods: {
    newlyAdd() {
      this.$router.push({
        name: "DecoratePage",
      });
    },
    // 获取列表
    getTemplateList() {
      templateList().then((res) => {
        console.log("模板列表：", res);
        this.tableData = res.rows;
      });
    },
    edit(tempate) {
      this.$router.push({
        path: "/O2OMall/config/template/decoratePage/index",
        query: {
          tempate,
        },
      });
    },
    // 更改状态
    updateTemplateStatus(item, e) {
      console.log("e:", e);
      console.log("item:", item.isOnLine);
      batchUpdateTemplateStatus({
        templateIds: [item.templateId],
        isOnLine: e,
      }).then((res) => {
        this.getTemplateList();
      });
    },
    // 删除模板
    deleteTemp(id) {
      this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTemplate({
            templateIds: [id],
          }).then((res) => {
            this.$message.success("删除成功!");
            this.getTemplateList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;

  .cardBox {
    padding: 10px;
    background: #eff1f4;
  }
}
</style>
