var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { stretch: "" },
          model: {
            value: _vm.data.style.type,
            callback: function ($$v) {
              _vm.$set(_vm.data.style, "type", $$v)
            },
            expression: "data.style.type",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "加微信群/个人微信号", name: "WeChat" } },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c("div", [_vm._v("添加活码")]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.content,
                          placement: "top-start",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question marL10" })]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f-end marT10" },
                  [
                    _c("el-button", { attrs: { type: "primary", plain: "" } }, [
                      _vm._v("添加活码"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form",
                { attrs: { model: _vm.data } },
                [
                  _c("el-divider"),
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fontS12",
                          staticStyle: { color: "#969799" },
                        },
                        [_vm._v("添加文案")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "按钮名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: _vm.data.style.btnName },
                            model: {
                              value: _vm.data.style.btnName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "btnName", $$v)
                              },
                              expression: "data.style.btnName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标题" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "个人微信号、群名称或活动标题",
                            },
                            model: {
                              value: _vm.data.style.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "title", $$v)
                              },
                              expression: "data.style.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "描述" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请添加描述" },
                            model: {
                              value: _vm.data.style.describe,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "describe", $$v)
                              },
                              expression: "data.style.describe",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "x-bc" },
                  [
                    _c("span", [_vm._v("入口图片")]),
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.raidoChange },
                        model: {
                          value: _vm.data.style.isDefault,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "isDefault", $$v)
                          },
                          expression: "data.style.isDefault",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("默认图片"),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("自定义图片"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.data.style.isDefault == 2
                  ? _c("div", { staticClass: "marT10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "addImgBox x-c",
                          on: { click: _vm.addImg },
                        },
                        [
                          _vm.data.style.imgUrl
                            ? _c("i", {
                                staticClass: "el-icon-plus",
                                staticStyle: {
                                  "font-size": "26px",
                                  color: "#bababa",
                                },
                              })
                            : _vm.data.style.coverImg != ""
                            ? _c("el-image", {
                                attrs: {
                                  src: _vm.data.style.imgUrl,
                                  width: "100%",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontS12",
                          staticStyle: { color: "#969799" },
                        },
                        [_vm._v("入口图建议1:1比例")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "x-bc marT10" }, [
                _c("div", { staticClass: "x-f" }, [
                  _c("span", { staticClass: "marR20 label" }, [
                    _vm._v("背景颜色"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
                ]),
                _c(
                  "div",
                  { staticClass: "x-f btnListBox" },
                  [
                    _c("el-color-picker", {
                      on: { change: _vm.selectBGcolor },
                      model: {
                        value: _vm.data.style.bgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "bgColor", $$v)
                        },
                        expression: "data.style.bgColor",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "关注公众号", name: "publicNum" } },
            [
              _c(
                "div",
                { staticClass: "marT20" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "fontS12",
                      staticStyle: { color: "#969799" },
                    },
                    [_vm._v("选择访问途径")]
                  ),
                  _c("div", { staticClass: "marT20" }, [
                    _c(
                      "div",
                      { staticClass: "x-bc" },
                      [
                        _c("span", { staticClass: "fontS12" }, [
                          _vm._v("小程序关注"),
                        ]),
                        _c("el-checkbox", {
                          model: {
                            value: _vm.data.style.applet,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "applet", $$v)
                            },
                            expression: "data.style.applet",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fontS12 marT10",
                        staticStyle: { color: "#969799" },
                      },
                      [
                        _vm._v(
                          " 仅在用户扫码进入微页面时可展示，用户可一键关注公众号，需要在微信后台开启 去配置 "
                        ),
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#1c75f4" },
                            attrs: {
                              href: "https://mp.weixin.qq.com/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("去配置")]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "marT20" }, [
                    _c(
                      "div",
                      { staticClass: "x-bc" },
                      [
                        _c("span", { staticClass: "fontS12" }, [
                          _vm._v("小程序转公众号文章关注"),
                        ]),
                        _c("el-checkbox", {
                          model: {
                            value: _vm.data.style.appletGopublicNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "appletGopublicNum", $$v)
                            },
                            expression: "data.style.appletGopublicNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fontS12 marT10",
                        staticStyle: { color: "#969799" },
                      },
                      [
                        _vm._v(
                          " 支持所有访问方式，用户点击后跳转公众号文章获取二维码，长按识别关注公众号 "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "marT20" }, [
                    _c(
                      "div",
                      { staticClass: "x-bc" },
                      [
                        _c("span", { staticClass: "fontS12" }, [_vm._v("H5")]),
                        _c("el-checkbox", {
                          model: {
                            value: _vm.data.style.h5,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "h5", $$v)
                            },
                            expression: "data.style.h5",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fontS12 marT10",
                        staticStyle: { color: "#969799" },
                      },
                      [_vm._v(" 支持所有访问方式，长按识别二维码关注 ")]
                    ),
                  ]),
                  _c("el-divider"),
                  _c(
                    "span",
                    {
                      staticClass: "fontS12 marT10",
                      staticStyle: { color: "#969799" },
                    },
                    [
                      _vm._v(
                        "若在小程序一个页面内同时选择了两款组件，用户扫码访问该页面时，仅展示微信官方组件。"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }