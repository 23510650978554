var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fitment" }, [
    _c("div", { staticClass: "hander x-bc bg-Color" }, [
      _c(
        "div",
        { staticClass: "pageList x-f" },
        _vm._l(_vm.pagelist, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "marR20 item x-c",
              class: { item2: index == _vm.pageIndex },
              on: {
                click: function ($event) {
                  return _vm.pageClick(item, index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.text) + " ")]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "btnList x-f" },
        [
          _c(
            "el-button",
            {
              staticClass: "whiteColor",
              attrs: {
                type: "info",
                size: "mini",
                border: "#a8a7a7",
                plain: "",
              },
              on: { click: _vm.getBack },
            },
            [_vm._v("返回 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "whiteColor",
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存 ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "x-start main" },
      [
        _c("left-template", {
          staticClass: "left-tempTag bg-Color",
          on: { addTemplate: _vm.addTemplate },
        }),
        _c("conter-preview", {
          staticClass: "conter x-f1",
          attrs: { headline: _vm.headline },
          on: { editTemp: _vm.editTemp },
        }),
        _c("right-statue", {
          staticClass: "right-statue bg-Color",
          attrs: { rightData: _vm.rightData },
          on: { setTemp: _vm.setTemp, setTemphead: _vm.setTemphead },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }